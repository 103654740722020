<template>
  <LoadingModal
      v-if="showLoadingModal"
      :show-modal="showLoadingModal"
  />
  <v-card>
    <v-card-item>
      <v-card-title id="card-title" v-if="username">{{ username }}, welcome to the Structure Developer Console.</v-card-title>
      <v-card-title v-else>Welcome to Structure Developer Console.</v-card-title>
      <v-card-text>Not sure how to get started? Just follow these steps below.</v-card-text>
    </v-card-item>
    <v-card-item>
      <v-expansion-panels variant="accordion">
        <v-expansion-panel v-for="(step, index) in steps" :key="index" :disabled="step.isCompleted">
          <v-expansion-panel-title>
            <v-icon :color="'primary'" class="mr-2" :icon="step.isCompleted ? 'fas fa-square-check' : 'fa-regular fa-square'"></v-icon>
            {{ step.title }}
          </v-expansion-panel-title>
          <v-expansion-panel-text>
            <template v-if="index === 0">
              Head over to the <router-link to="/console/downloads/ios-sdk">SDK Downloads</router-link> page and download the latest version of Structure SDK for iOS. This package includes source code and sample projects for various use cases. You can't develop an app without it!
            </template>
            <template v-else-if="index === 1">
              Head over to the <router-link to="/console/vault/sdk-keys">keys vault</router-link> and create your first SDK key, connecting your app's bundle id and the SDK.
            </template>
            <template v-else-if="index === 2">
              Learn how the classes and methods of our SDK work to enable our magic with yours. Check out our <router-link to="/documentation">documentation</router-link> for detailed tutorials.
            </template>
            <template v-else>
              {{ step.text }}
            </template>
          </v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card-item>
  </v-card>
</template>

<script>
import LoadingModal from '@/modals/LoadingModal.vue';

import { ref, computed, onMounted } from 'vue';
import { useStore } from 'vuex';

import { useAuth0 } from '@auth0/auth0-vue';
import axios from 'axios';

import { useSCPRequest } from '../composables.js';

export default {
  name: 'GetStarted',
  components: {
      LoadingModal
  },
  setup() {
    const { user } = useAuth0();
    const username = user.value.given_name;
    const store = useStore();

    const { makeSCPRequest } = useSCPRequest(store);
    const { getAccessTokenSilently } = useAuth0();

    const permissions = computed(() => store.state.authentication.permissions || []);
    const showLoadingModal = ref(true);

    let sdkData;

    const steps = ref([
      {
        icon: 'fas fa-square-check',
        title: 'Download the SDK',
        text: 'Head over to the SDK Downloads page and download the latest version of Structure SDK for iOS. This package includes source code and sample projects for various use cases. You can\'t develop an app without it!',
        isCompleted: false
      },
      {
        icon: 'fa-regular fa-square',
        title: 'Generate your first SDK key',
        text: 'Head over to the keys vault and create your first SDK key, connecting your app\'s bundle id and the SDK.',
        isCompleted: false
      },
      {
        icon: 'fa-regular fa-square',
        title: 'Review the tutorials',
        text: 'Learn how the classes and methods of our SDK work to enable our magic with yours.',
        isCompleted: false
      },
      // {
      //   icon: 'fa-regular fa-square',
      //   title: 'Set up autopay',
      //   text: 'As per our agreement, you only pay for the usage your app accrues. Set up autopay for an uninterrupted experience for you and your users.',
      //   isCompleted: false
      // }
    ]);

    const checkOnboardingStatus = async () => {
      try {
        const token = await getAccessTokenSilently();
        const headers = { Authorization: `Bearer ${token}` };
        const response = await axios.get(`${process.env.VUE_APP_CBE_BASE_URL}/piccolo/users/onboardings/${user.value.sub}`, { headers });
        const onboardingData = response.data;

        if (permissions.value.includes('sdk_access')) {
          sdkData = await makeSCPRequest('/v0.1/sdk/keys', 'get');
        }

        if (onboardingData.has_downloaded_sdk) {
          steps.value[0].isCompleted = true;
        }

        if (sdkData && sdkData.length > 0) {
          steps.value[1].isCompleted = true;
        }

        if (onboardingData.has_read_documentation) {
          steps.value[2].isCompleted = true;
        }

        if (onboardingData.has_set_billing) {
          steps.value[3].isCompleted = true;
        }
      } catch (error) {
        console.error('Error checking onboarding status:', error);
      }
      showLoadingModal.value = false;
    };

    onMounted(() => {
      checkOnboardingStatus();
    });

    return {
      user,
      username,
      steps,
      showLoadingModal
    };
  }
};
</script>