<template>
    <div v-if="dataFetched">
        <table aria-describedby="account-table">
            <tbody>
                <tr>
                    <th>Name</th>
                    <td>{{ user.name }}</td>
                </tr>
                <tr>
                    <th>Email</th>
                    <td>{{ user.email }}</td>
                </tr>
                <tr>
                    <th>Status</th>
                    <td>
                        <span v-if="!permissions || permissions.length === 0">
                            <p>Inactive <v-icon :icon="'fas fa-spinner'" spin style="color: #3ed333;"></v-icon></p>
                            <p>Please reach out to <a href="mailto:partners@structure.io" target="_blank">our cloud team</a> to finish account activation.</p>
                        </span>
                        <span v-else>Active</span>
                    </td>
                </tr>
                <tr v-if="hasRoles">
                    <th>Roles</th>
                    <td>
                        <div v-for="(role, index) in formattedRoles" :key="index" class="access-identifier">
                            {{ role }}
                        </div>
                    </td>
                </tr>
                <tr v-if="hasPermissions">
                    <th>Permissions</th>
                    <td>
                        <div v-for="(permission, index) in formattedPermissions" :key="index" class="access-identifier">
                            {{ permission }}
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <div v-else>
        Loading...
    </div>
</template>

<script>
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import { useAuth0 } from '@auth0/auth0-vue';
import { jwtDecode } from 'jwt-decode';

export default {
    name: 'AccountPage',
    setup() {
        const store = useStore();
        const { user } = useAuth0();
		const { getAccessTokenSilently } = useAuth0();
        const permissions = ref(null);
		const dataFetched = ref(false);

        // Fetch permissions from store
        const roles = store.state.authentication.roles;

        // Create aliases for roles and permissions
        const formattedRoles = computed(() => roles?.map(role => formatRole(role)) || []);
        const formattedPermissions = computed(() => permissions.value?.map(permission => formatPermission(permission)) || []);

        // Helper functions to format roles and permissions
        const formatRole = (role) => {
            return role.replace(/-/g, ' ');
        };

        const formatPermission = (permission) => {
            return permission.replace(/_/g, ' ');
        };

        // Check if there are roles or permissions
        const hasRoles = computed(() => formattedRoles.value.length > 0);
        const hasPermissions = computed(() => formattedPermissions.value.length > 0);

        // Fetch permissions asynchronously
        const fetchPermissions = async () => {
            try {
                const token = await getAccessTokenSilently();
                const decoded = jwtDecode(token);
                permissions.value = decoded.permissions;
				dataFetched.value = true;
            } catch (error) {
                console.error('Error fetching permissions:', error);
            }
        };

        // Trigger fetching of permissions when component is mounted
        fetchPermissions();

        return {
            permissions,
            user,
            formattedRoles,
            formattedPermissions,
            hasRoles,
            hasPermissions,
            fetchPermissions,
			dataFetched
        };
    },
};
</script>

<style lang="scss" scoped>
	@import '../assets/styles/AccountView.scss'
</style>
