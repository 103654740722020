<template>
  <v-card v-if="releaseData && app == 'special-files'" class="pa-10 rounded-lg">
    <h1 id="special-file-title">Special Files</h1>
    <div class="special-file-container">
      <template v-for="specialFile in releaseData" :key="specialFile.id">
        <div class="special-file-card">
          <h2 class="special-file-name" v-if="specialFile.app == 'StructureLite'">Structure Lite</h2>
          <h2 v-else>{{ specialFile.app }}</h2>
          <p class="special-file-released"><em>Released {{ formatReleaseDate(specialFile.released_on) }}</em></p>
          <p class="special-file-version">Version {{ specialFile.version }}</p>
          <template v-if="!hasBeenDownloaded(specialFile.id)">
            <v-btn class="mt-2 special-file-download-btn" color="primary" prepend-icon="fas fa-download"
                  @click="getSpecialFileDownloadUrl(specialFile.id)" :loading="isDownloading[specialFile.id]">
              Download now
            </v-btn>
          </template>
          <template v-if="hasBeenDownloaded(specialFile.id)">
            <v-btn class="mt-2 special-file-download-btn" :disabled="hasBeenDownloaded(specialFile.id)" color="primary" prepend-icon="fas fa-circle-check">Downloaded</v-btn>
          </template>
        </div>
      </template>
    </div>
  </v-card>

  <v-card v-else-if="releaseData && (app == 'ios-sdk' || app == 'xp-sdk')" class="pa-10 rounded-lg">
    <template v-if="Array.isArray(releaseData)">
      <h1 class="sdk-title">{{ releaseData[0].title }}</h1>
      <p class="sdk-released"><em>Released {{ formatReleaseDate(releaseData[0].released_on) }}</em></p>
      <v-divider class="pt-2 pb-2"></v-divider>
      <div class="sdk-notes" v-html="releaseData[0].release_notes"></div>
      <template v-if="!hasBeenDownloaded(releaseData[0].id)">
        <v-btn class="mt-2 float-right sdk-download-btn" color="primary" prepend-icon="fas fa-download"
               @click="getSDKDownloadUrl(releaseData[0])" :loading="isDownloading[releaseData[0].id]">
          Download now
        </v-btn>
      </template>
      <template v-if="hasBeenDownloaded(releaseData[0].id)">
        <v-btn class="mt-2 float-right" :disabled="hasBeenDownloaded(releaseData[0].id)" color="primary" prepend-icon="fas fa-circle-check">Downloaded</v-btn>
      </template>
    </template>
    <template v-else>
      <h1>{{ releaseData.title }}</h1>
      <p><em>Released {{ formatReleaseDate(releaseData.released_on) }}</em></p>
      <v-divider class="pt-2 pb-2"></v-divider>
      <div v-html="releaseData.release_notes"></div>
      <template v-if="!hasBeenDownloaded(releaseData.id)">
        <v-btn class="mt-2 float-right" color="primary" prepend-icon="fas fa-download"
               @click="getSDKDownloadUrl(releaseData)" :loading="isDownloading[releaseData.id]">
          Download now
        </v-btn>
      </template>
      <template v-if="hasBeenDownloaded(releaseData.id)">
        <v-btn class="mt-2 float-right" :disabled="hasBeenDownloaded(releaseData.id)" color="primary" prepend-icon="fas fa-circle-check">Downloaded</v-btn>
      </template>
    </template>
    <LoadingModal v-if="showLoadingModal" :show-modal="showLoadingModal" />
  </v-card>
</template>

<script>
import { onMounted, computed, ref, watch, reactive } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { useAuth0 } from '@auth0/auth0-vue';
import axios from 'axios';
import LoadingModal from '@/modals/LoadingModal.vue';

export default {
  name: 'SDKDownload',
  components: {
    LoadingModal,
  },

  setup() {
    const store = useStore();
    const route = useRoute();

    const { user, getAccessTokenSilently } = useAuth0();

    const app = computed(() => route.params.package);
    const showLoadingModal = computed(() => store.state.showLoadingModal);
    const releaseData = ref(null);
    const token = ref('');
    const downloadsStatus = reactive({});
    const isDownloading = reactive({});

    const formatReleaseDate = (dateString) => {
      const date = new Date(dateString);
      return date.toLocaleString();
    };

    const getAccessToken = async () => {
    try {
      token.value = await getAccessTokenSilently();
      return { headers: { Authorization: `Bearer ${token.value}` } };
    } catch (err) {
      console.error('Error getting token:', err);
      return { headers: {} };
    }
  };

    const fetchData = async () => {
      try {
        store.commit('SET_SHOW_LOADING_MODAL', true);
        const authHeaders = await getAccessToken();
        let iosResponse, xpResponse, specialFilesResponse;

        switch (app.value) {
          case 'ios-sdk':
            iosResponse = await axios.get(`${process.env.VUE_APP_CBE_BASE_URL}/piccolo/releases/latest`, authHeaders);
            releaseData.value = [{ ...iosResponse.data, title: `SDK ${iosResponse.data.version} for iOS` }];
            break;

          case 'xp-sdk':
            xpResponse = await axios.get(`${process.env.VUE_APP_CBE_BASE_URL}/piccolo/releases/cross-platform`, authHeaders);
            releaseData.value = [{ ...xpResponse.data, title: `SDK ${xpResponse.data.version} (Cross Platform)` }];
            break;

          case 'special-files':
            specialFilesResponse = await axios.get(`${process.env.VUE_APP_CBE_BASE_URL}/piccolo/specialfiles/${user.value.sub}`, authHeaders);
            console.log("fetching special files")
            releaseData.value = specialFilesResponse.data;
            break;
        }
        store.commit('SET_SHOW_LOADING_MODAL', false);
      } catch (err) {
        console.error('Error fetching data:', err);
        store.commit('SET_SHOW_LOADING_MODAL', false);
      }
    };

    const getSpecialFileDownloadUrl = async (specialFileId) => {
      console.log(specialFileId);
  if (!specialFileId) return;

  // Set loading state - use direct mutation for reactive object
  isDownloading[specialFileId] = true;

  try {
    const authHeaders = await getAccessToken();
    const response = await axios.get(
      `${process.env.VUE_APP_CBE_BASE_URL}/piccolo/specialfiles/${specialFileId}/download-url`,
      authHeaders
    );

    // Open URL in new tab
    window.open(response.data.url, '_blank');

    // Record the download
    await handleDownload(specialFileId);
  } catch (err) {
    console.error('Error getting download URL:', err);
  } finally {
    // Clear loading state
    isDownloading[specialFileId] = false;
  }
};

    const getSDKDownloadUrl = async (sdkRelease) => {
      try {
        isDownloading[sdkRelease.id] = true;
        const authHeaders = await getAccessToken();
        const platform = app.value === 'ios-sdk' ? 'ios' : 'xp';
        const response = await axios.get(
          `${process.env.VUE_APP_CBE_BASE_URL}/piccolo/releases/${platform}/download-url`,
          authHeaders
        );

        // Open the download URL in a new tab
        window.open(response.data.url, '_blank');

        // Record the download
        await handleSDKDownload(sdkRelease);
      } catch (err) {
        console.error('Error getting download URL:', err);
      } finally {
        isDownloading[sdkRelease.id] = false;
      }
    };

    const handleDownload = async (specialFile) => {
      const now = new Date();
      const formattedDate = now.toISOString().slice(0, -1);
      const payload = {
        created_on: formattedDate,
        special_file_id: specialFile.id,
        user_id: `${user.value.sub}`,
        version: specialFile.version,
      };
      try {
        const authHeaders = await getAccessToken();
        await sendDownloadRequest(`${process.env.VUE_APP_CBE_BASE_URL}/piccolo/specialfiles/download`, payload, authHeaders);
        downloadsStatus[specialFile.id] = true; // Update the downloadsStatus object
      } catch (err) {
        console.error('Error recording download:', err);
      }
    };

    const handleSDKDownload = async (releaseData) => {
      const now = new Date();
      const formattedDate = now.toISOString().slice(0, -1);
      const authHeaders = await getAccessToken();
      const payload = {
        created_on: formattedDate,
        sdk_release_id: releaseData.id,
        user_id: `${user.value.sub}`,
        version: releaseData.version,
      };
      try {
        await sendDownloadRequest(`${process.env.VUE_APP_CBE_BASE_URL}/piccolo/releases/download`, payload, authHeaders);
        downloadsStatus[releaseData.id] = true; // Update the downloadsStatus object
        await updateOnboarding();
      } catch (err) {
        console.error('Error recording download:', err);
      }
    };

    const sendDownloadRequest = (url, payload, headers) => {
      return axios.post(url, payload, headers);
    };

    const updateOnboarding = async () => {
      try {
        const authHeaders = await getAccessToken();
        const auth0Id = user.value.sub;
        await axios.patch(`${process.env.VUE_APP_CBE_BASE_URL}/piccolo/users/onboardings/${auth0Id}`, {
          has_downloaded_sdk: true,
        }, authHeaders);
      } catch (error) {
        console.error('Error updating onboarding data:', error);
      }
    };

    const hasBeenDownloaded = (id) => {
      return downloadsStatus[id] || false;
    };

    watch(
      () => app.value,
      async () => {
        releaseData.value = null;
        await fetchData();
        Object.keys(downloadsStatus).forEach(key => {
          delete downloadsStatus[key];
        });
      }
    );

    onMounted(async () => {
      await fetchData();
    });

    return {
      app,
      showLoadingModal,
      releaseData,
      formatReleaseDate,
      hasBeenDownloaded,
      getSpecialFileDownloadUrl,
      getSDKDownloadUrl,
      handleDownload,
      handleSDKDownload,
      downloadsStatus,
      isDownloading
    };
  },
};
</script>

<style scoped>
  .special-file-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: left;
  }
  .special-file-card {
    background-color: black;
    padding: 5%;
    border-radius: 10px;
    margin: 2.5%;
  }
</style>