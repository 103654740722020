<template>
    <v-card class="pa-10 rounded-large">
        <h1>Oops! Something went wrong.</h1>
        <p>Please try your action again. If this issue persists, please reach out to <a href="mailto:developers@structure.io">our Developer support team.</a>.</p>
    </v-card>
</template>

<script>

  export default {
    name: 'ErrorView',
    setup() {
    },
  };
  </script>