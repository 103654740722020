<template>

    <DarkTextHeroShort
        :title="'Schedule a Call'"
        :subtitle="'Questions? Thoughts? Want to take the next step? Book a call with us and we\'ll walk you through everything.'"
    />

    <ContactForm />


</template>

<script>
import DarkTextHeroShort from '@/components/DarkTextHeroShort.vue';
import ContactForm from '@/components/ContactForm.vue';

export default {
  name: 'DemoView',
  components: {
    DarkTextHeroShort,
    ContactForm,
  },
  setup() {
  }
}
</script>

<style scoped lang="scss">
    @import '@/assets/styles/DemoView.scss'
    @import '@/assets/styles/StrButtons.scss'
</style>