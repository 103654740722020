<template>
  <v-app>
    <DashboardTopNav />
    <DashboardAppNav v-if="shouldSideNavShow() && !isMobile"/>
    <DashboardAppNavMobile v-if="shouldSideNavShow() && isMobile"/>
    <v-main>
      <!-- <v-main class="d-flex align-center justify-center" style="min-height: 300px;"> -->
      <router-view></router-view>
    </v-main>
  </v-app>
  <FooterContainer v-if="!shouldSideNavShow()" />

</template>

<script>
import { useAuth0 } from "@auth0/auth0-vue";
import { useRoute } from 'vue-router';
import { ref, onMounted, onUnmounted } from 'vue';

import { publicPages } from '@/config/routesConfig';

import DashboardTopNav from './components/navigation/DashboardTopNav.vue';
import DashboardAppNav from './components/navigation/DashboardAppNav.vue';
import DashboardAppNavMobile from './components/navigation/DashboardAppNavMobile.vue';
import FooterContainer from './components/navigation/FooterContainer.vue';

export default {
  name: 'App',
  components: {
    DashboardTopNav,
    DashboardAppNav,
    DashboardAppNavMobile,
    FooterContainer,
  },
  setup() {
    const { isAuthenticated } = useAuth0();

    const isMobile = ref(window.innerWidth <= 1280);

    const updateIsMobile = () => {
      isMobile.value = window.innerWidth <= 1280;
    };

    const shouldSideNavShow = () => {
      const route = useRoute();
      return isAuthenticated.value && !publicPages.includes(route.path);
    };


    onMounted(() => {
      window.addEventListener('resize', updateIsMobile);
    });

    onUnmounted(() => {
      window.removeEventListener('resize', updateIsMobile);
    });

    return {
      isAuthenticated,
      isMobile,
      shouldSideNavShow
    };
  },
};
</script>


<style>

@import './assets/styles/variables.css';
@import './assets/styles/VuetifyMods.css';

@font-face {
    font-family: Degular-Regular;
    src: url('./assets/fonts/Degular/Degular-Regular.otf');
}

* {
  box-sizing: border-box;
}

body {
      margin: 0;
      padding: 0;
    }
</style>