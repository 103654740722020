export const publicPages = [
    '/',
    '/products/structure-sdk',
    '/products/structure-lite',
    '/products/structure-cloud',
    '/products/call',
    '/terms',
    '/eula',
    '/auth0callback',
    '/error',
];