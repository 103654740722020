<template>
<section class="demo-section">
    <div class="demo-section-tag">Demo</div>
    <h1>Ready to get started?</h1>
    <p>Questions? Thoughts? Want to take the next step? Book a demo with us and we'll walk you through everything.</p>

    <RouterLink to="/products/call"><div class="demo-section-cta">Set Up a Call</div></RouterLink>
</section>
</template>

<script>

</script>

<style lang="scss" scoped>
    @import '@/assets/styles/StrVariables.scss'
    @import '@/assets/styles/DemoSection.scss'
</style>