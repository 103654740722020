<template>
    <div class="modal" v-if="showModal">
		<div class="modal-content">
			<span class="close" @click="closeModal">&times;</span>
			<v-form id="api-key-form" v-if="activeView === 'api-keys'" @submit.prevent="submitApiKeysForm">
				<h1>New API Key</h1>
					<v-text-field
						v-model="apiKeyForm.name"
						label="API Key Name"
						id="api-key-name"
						class="mt-5"
						test-id="api-key-input"
						required
					></v-text-field>

				<v-btn id="api-key-submit" type="submit">Submit</v-btn>
			</v-form>

			<v-form id="sdk-key-form" v-else-if="activeView === 'sdk-keys'" @submit.prevent="submitSdkKeysForm">
				<h1>New SDK Key</h1>
				<v-text-field
						v-model="sdkKeyForm.name"
						label="SDK Key Name"
						id="sdk-key-name"
						class="mt-5"
						test-id="sdk-key-input-name"
						required
					></v-text-field>
				<v-text-field
						v-model="sdkKeyForm.bundle"
						label="Bundle ID"
						id="bundle-id"
						:class="{ 'is-invalid': !isBundleIdValid }"
						@input="hasStartedTyping = true"
						test-id="sdk-key-input-bundle"
						style="font-family: monospace"
						required
					></v-text-field>
					<div class="invalid-feedback" v-if="!isBundleIdValid && hasStartedTyping">
					Bundle ID must contain only alphanumeric characters, hyphens, and periods.
				</div>


				<v-btn id="sdk-key-submit" type="submit" :disabled="!isBundleIdValid">Submit</v-btn>
			</v-form>
		</div>
	</div>
</template>

<script>
	import { ref, computed } from 'vue';
	import { useStore } from 'vuex';

	export default {
		name: 'DynamicFormModal',
		props: {
			showModal: Boolean,
			clickedItemLabel: String,
			emitFunction: Function,
		},
		setup(props) {
			const store = useStore();
			const activeView = computed(() => props.clickedItemLabel);
			const BUNDLE_ID_REGEX = /^[a-zA-Z0-9.-]+$/;
			const hasStartedTyping = ref(false);


			const apiKeyForm = ref({
				name: '',
			});

			const sdkKeyForm = ref({
				name: '',
				bundle: '',
			});

			const validateBundleId = (bundleId) => {
				return BUNDLE_ID_REGEX.test(bundleId);
			}

			const isBundleIdValid = computed(() => {
				return validateBundleId(sdkKeyForm.value.bundle);
			});


			const closeModal = () => {
				store.commit('SET_SHOW_DYNAMIC_MODAL', false);
			}

			const submitApiKeysForm = () => {
			// Handle form submission for API Keys form
				props.emitFunction('submit-api-keys-form', apiKeyForm.value);
				closeModal();
			}

			const submitSdkKeysForm = () => {
				if (isBundleIdValid.value) {
					props.emitFunction('submit-sdk-keys-form', sdkKeyForm.value);
					closeModal();
				}
			}


			return {
				activeView,
				apiKeyForm,
				sdkKeyForm,
				isBundleIdValid,
				hasStartedTyping,
				closeModal,
				submitApiKeysForm,
				submitSdkKeysForm,
			};
		},
	};
</script>


<style lang="scss" scoped>
	@import '@/assets/styles/CommonModalStyles.scss'
	@import '@/assets/styles/DynamicFormModal.scss'

</style>
