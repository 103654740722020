import { createAuth0 } from '@auth0/auth0-vue';
import { createApp } from 'vue';
import store from './store';
import App from './App.vue';
import { router } from './router/index';

import VueApexCharts from 'vue3-apexcharts';
import { VueReCaptcha } from 'vue-recaptcha-v3';

// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import { aliases, fa } from 'vuetify/iconsets/fa'

import '@fortawesome/fontawesome-free/css/all.css'

const customDarkTheme = {
  dark: true,
  colors: {
    background: "#16181a",
    surface: "#323436",
    primary: "#3297e3",
    secondary: "#5b429f",
    error: "#da3135",
    info: "#646668",
    success: "#0d9252",
    warning: "#ff712b",
  },
};

const vuetify = createVuetify({
  components,
  directives,
  theme: {
    defaultTheme: 'customDarkTheme',
    themes: {
      customDarkTheme,
    },
  },
  icons: {
    defaultSet: 'fa',
    aliases,
    sets: {
      fa,
    },
  },
})

const app = createApp(App);

app.use(store)
.use(router)
.use(vuetify)
.use(VueApexCharts)
.use(VueReCaptcha, {siteKey: process.env.VUE_APP_RECAPTCHA_SITE_KEY})
.use(
    createAuth0({
        domain: process.env.VUE_APP_AUTH0_DOMAIN,
        clientId: process.env.VUE_APP_AUTH0_CLIENT_ID,
        authorizationParams: {
            redirect_uri: process.env.VUE_APP_AUTH0_CALLBACK_URL,
            audience: process.env.VUE_APP_AUTH0_API_AUDIENCE
        },
    })
);

app.mount('#app');
