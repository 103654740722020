<template>
    <footer class="str-footer">
      <div class="footer-container">
        <div class="footer-left">
          <a href="https://structure.io">
            <img
              class="str-footer-logo"
              src="https://store-4fwfwlzl1t.mybigcommerce.com/content/images/logos/structure-white-gradient.svg"
            />
          </a>
          <div class="footer-address-block">
            <address>
              1155 Canyon Blvd<br />
              Suite 105<br />
              Boulder, CO 80302
            </address>
            <div class="footer-phone">+1 650-744-0331</div>
          </div>
        </div>
        <div class="footer-right">
          <div
            v-for="(menu, index) in footerMenus"
            :key="index"
            class="footer-right-column"
          >
            <p @click="toggleMenu(index)">
              <strong>{{ menu.title }}</strong>
              <img
                class="footer-btn footer-btn-open"
                :src="mobileIcon('plus')"
                :class="{ active: menu.open }"
                alt="toggle icon"
            />

            </p>
            <ul v-show="menu.open" class="footer-menu">
              <li
                v-for="(item, idx) in menu.items"
                :key="idx"
              >
                <RouterLink v-if="item.type === 'route'" :to="item.href">
                  {{ item.label }}
                </RouterLink>
                <a v-else :href="item.href">{{ item.label }}</a>
              </li>
            </ul>
          </div>
        </div>
        <div class="footer-bottom-1">
          <div class="footer-social-links">
            <a
              v-for="(social, idx) in socialLinks"
              :key="idx"
              :href="social.href"
              target="_blank"
            >
              <img :src="social.icon" :alt="social.label + ' icon'" />
            </a>
          </div>
        </div>
        <div class="footer-bottom-2">
          <p class="footer-copyright">
            Copyright &copy; {{ currentYear }} Structure. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  </template>

<script>
  import { ref, onMounted, onBeforeUnmount } from 'vue';

export default {
  name: 'FooterContainer',
  setup() {
    const currentYear = new Date().getFullYear();

    const footerMenus = ref([
      {
        title: 'Products',
        open: false,
        items: [
          // { label: 'Structure SDK', href: '/products/structure-sdk', type: 'route' },
          // { label: 'Structure Cloud', href: '/products/structure-cloud', type: 'route' },
          { label: 'Schedule a Call', href: '/products/call', type: 'route' },
        ],
      },
      {
        title: 'Resources',
        open: false,
        items: [
          { label: 'Documentation', href: 'https://developer.structure.io/docs/documentation/structure/' },
          { label: 'Tutorials', href: 'https://developer.structure.io/docs/tutorials/tutorials/' },
          { label: 'EULA', href: '/eula', type: 'route' },
        ],
      },
      {
        title: 'Support',
        open: false,
        items: [
          { label: 'Help Center', href: 'https://support.structure.io' },
          { label: 'Contact Us', href: 'https://structure.io/contact' },
        ],
      },
    ]);

    const socialLinks = [
      {
        label: 'LinkedIn',
        href: 'https://www.linkedin.com/company/structure-io',
        icon: 'https://structure.io/content/images/icons/icon-linkedin.svg',
      },
      {
        label: 'Facebook',
        href: 'https://www.facebook.com/structure3d/',
        icon: 'https://structure.io/content/images/icons/icon-facebook.svg',
      },
      {
        label: 'X',
        href: 'https://twitter.com/structure',
        icon: 'https://structure.io/content/images/icons/icon-x.svg',
      },
    ];

    const toggleMenu = (index) => {
      footerMenus.value[index].open = !footerMenus.value[index].open;
    };

    const mobileIcon = (icon) => require(`@/assets/images/icons/${icon}.svg`);

    const handleResize = () => {
      const isDesktop = window.innerWidth > 912;
      footerMenus.value.forEach((menu) => {
        menu.open = isDesktop; // Ensure all menus are open on desktop
      });
    };

    onMounted(() => {
      window.addEventListener('resize', handleResize);
      handleResize(); // Initialize state on mount
    });

    onBeforeUnmount(() => {
      window.removeEventListener('resize', handleResize);
    });

    return {
      currentYear,
      footerMenus,
      socialLinks,
      toggleMenu,
      mobileIcon,
    };
  },
};

</script>

<style scoped lang="scss">
    @import '@/assets/styles/FooterContainer.scss'
</style>