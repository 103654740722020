<template>
    <div class="contact-form-container">
      <template v-if="!formSubmitted && !submissionError">
        <h1>Call Sign Up Form</h1>
        <form @submit.prevent="validateAndSubmit" class="contact-form">
          <div class="form-field">
            <label for="name">Name<span class="required">*</span></label>
            <div v-if="validationErrors.name" class="error-message">
              {{ validationErrors.name }}
            </div>
            <input
              type="text"
              id="name"
              v-model="form.name"
              :class="{ 'input-error': validationErrors.name }"
              @input="clearFieldError('name')"
            />
          </div>

          <div class="form-field">
            <label for="company">Company<span class="required">*</span></label>
            <div v-if="validationErrors.company" class="error-message">
              {{ validationErrors.company }}
            </div>
            <input
              type="text"
              id="company"
              v-model="form.company"
              :class="{ 'input-error': validationErrors.company }"
              @input="clearFieldError('company')"
            />
          </div>

          <div class="form-field">
            <label for="email">Email<span class="required">*</span></label>
            <div v-if="validationErrors.email" class="error-message">
              {{ validationErrors.email }}
            </div>
            <input
              type="email"
              id="email"
              v-model="form.email"
              :class="{ 'input-error': validationErrors.email }"
              @input="clearFieldError('email')"
            />
          </div>

          <div class="form-field">
            <label for="phone">Phone<span class="required">*</span></label>
            <div v-if="validationErrors.phone" class="error-message">
              {{ validationErrors.phone }}
            </div>
            <input
              type="tel"
              id="phone"
              v-model="form.phone"
              :class="{ 'input-error': validationErrors.phone }"
              @input="clearFieldError('phone')"
            />
          </div>

          <div class="form-field">
            <label for="industry">Industry<span class="required">*</span></label>
            <div v-if="validationErrors.industry" class="error-message">
              {{ validationErrors.industry }}
            </div>
            <select
              id="industry"
              v-model="form.industry"
              :class="{ 'input-error': validationErrors.industry }"
              @change="clearFieldError('industry')"
            >
              <option disabled value="">Select an industry</option>
              <option>Healthcare & Medical Devices</option>
              <option>Orthotics & Prosthetics</option>
              <option>Physical Therapy & Rehabilitation</option>
              <option>Sports & Fitness</option>
              <option>Manufacturing & Industrial</option>
              <option>Education & Research</option>
              <option>Gaming & Entertainment</option>
              <option>Construction & Engineering</option>
              <option>Automotive & Aerospace</option>
              <option>Architecture & Design</option>
              <option>Fashion & Apparel</option>
              <option>Consumer Electronics</option>
              <option>Other</option>
            </select>
          </div>

          <div class="form-field">
            <label for="clientSize">Client Base Size<span class="required">*</span></label>
            <div v-if="validationErrors.clientSize" class="error-message">
              {{ validationErrors.clientSize }}
            </div>
            <select
              id="clientSize"
              v-model="form.clientSize"
              :class="{ 'input-error': validationErrors.clientSize }"
              @change="clearFieldError('clientSize')"
            >
              <option disabled value="">Select client base size</option>
              <option>1-10 Users</option>
              <option>11-50 Users</option>
              <option>51-200 Users</option>
              <option>201-500 Users</option>
              <option>501-1,000 Users</option>
              <option>1,001+ Users</option>
            </select>
          </div>

          <button
            type="submit"
            class="str-btn-primary"
            :disabled="isSubmitting"
          >
            {{ isSubmitting ? 'Submitting...' : 'Submit' }}
          </button>
        </form>
      </template>

      <template v-else-if="submissionError">
        <h1>Something went wrong.</h1>
        <p>Please try your submission again. If this issue persists, please contact us at <a href="mailto:developers@structure.io">developers@structure.io</a>.</p>
      </template>

      <template v-else>
        <h1>We've received your request.</h1>
        <p>Our all-star team will reach out to you shortly to find a time that works best for you.</p>
      </template>
    </div>
  </template>

<script>
import { ref, reactive, onMounted } from "vue";
import { useReCaptcha } from "vue-recaptcha-v3";
import axios from 'axios';

export default {
  name: "ContactForm",
  setup() {
    const form = ref({
      name: "",
      company: "",
      email: "",
      phone: "",
      industry: "",
      clientSize: "",
    });

    const validationErrors = reactive({});
    const isSubmitting = ref(false);
    const formSubmitted = ref(false);
    const submissionError = ref(false);

    const { executeRecaptcha, recaptchaLoaded } = useReCaptcha();

    onMounted(async () => {
      await recaptchaLoaded();
    });

    const clearFieldError = (fieldName) => {
        // Remove error if the value is valid
        const value = form.value[fieldName];
        if (value && value.trim() !== '') {
            delete validationErrors[fieldName];
        }
    };

    const validateForm = () => {
      // Clear previous errors
      Object.keys(validationErrors).forEach(key => {
        delete validationErrors[key];
      });

      // Validate each field
      Object.keys(form.value).forEach(key => {
        const value = form.value[key];
        if (!value || (typeof value === 'string' && value.trim() === '')) {
          validationErrors[key] = `${key.charAt(0).toUpperCase() + key.slice(1)} is required`;
        }
      });

      return Object.keys(validationErrors).length === 0;
    };

    const validateAndSubmit = async () => {
      // First, validate the form
      if (!validateForm()) {
        return;
      }

      try {
        // Execute reCAPTCHA
        const token = await executeRecaptcha("form_submission");

        // Set submitting state
        isSubmitting.value = true;

        // Submit form data
        const response = await axios.post(`${process.env.VUE_APP_CBE_BASE_URL}/helpscout`, {
          ...form.value,
          recaptchaToken: token
        });

        // Check if the response indicates successful submission
        if (response.status === 200) {
          formSubmitted.value = true;
        } else {
          // If response is not successful, trigger error state
          submissionError.value = true;
        }
      } catch (error) {
        // Handle submission error
        submissionError.value = true;
      } finally {
        // Reset submitting state
        isSubmitting.value = false;
      }
    };

    return {
      form,
      validationErrors,
      isSubmitting,
      formSubmitted,
      submissionError,
      validateAndSubmit,
      clearFieldError,
    };
  },
};
</script>

  <style scoped lang="scss">
  @import "@/assets/styles/StrVariables.scss"
  @import "@/assets/styles/ContactForm.scss"
  @import "@/assets/styles/StrButtons.scss"
  </style>