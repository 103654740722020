<template>
    <section class="features-table">
      <h2>{{ title }}</h2>
      <p>{{ subtitle }}</p>
      <table>
        <thead>
          <tr>
            <th v-for="(header, index) in tableHeaders" :key="index">
              {{ header }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(row, rowIndex) in tableData" :key="rowIndex">
            <td>{{ row.feature }}</td>
            <td>
              <img
                :src="row.sdk ? checkIcon : xIcon"
                :alt="row.sdk ? 'Yes' : 'No'"
              />
            </td>
            <td>
              <img
                :src="row.lite ? checkIcon : xIcon"
                :alt="row.lite ? 'Yes' : 'No'"
              />
            </td>
            <td>
              <img
                :src="row.cloud ? checkIcon : xIcon"
                :alt="row.cloud ? 'Yes' : 'No'"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </section>
  </template>

  <script>
  import checkIcon from '@/assets/images/icons/check.svg'
  import xIcon from '@/assets/images/icons/x.svg'

  export default {
    name: 'FeaturesTable',
    props: {
      title: {
        type: String,
        required: true
      },
      subtitle: {
        type: String,
        required: true
      },
    },
    setup() {
      const tableHeaders = ['Feature', 'Structure SDK', '+Structure Lite', 'Structure Cloud']

      const tableData = [
        { feature: 'Scan Quality Indicator (SQI)', sdk: true, lite: true, cloud: false },
        { feature: 'ST01 Support', sdk: true, lite: true, cloud: false },
        { feature: 'iPhone 15/16 Support', sdk: true, lite: true, cloud: false },
        { feature: '3DFaceScan Source Code', sdk: false, lite: true, cloud: false },
        { feature: '3DFootScan Source Code', sdk: false, lite: true, cloud: false },
        { feature: '3DChonkScan Source Code', sdk: false, lite: true, cloud: false },
        { feature: 'Upload/Download Scans', sdk: false, lite: false, cloud: true },
        { feature: 'Automatic Foot Measurements', sdk: false, lite: false, cloud: true },
        { feature: 'Navicular Drop Measurements', sdk: false, lite: false, cloud: true },
        { feature: 'Foot Landmark Detection', sdk: false, lite: false, cloud: true },
      ]

      return {
        tableHeaders,
        tableData,
        checkIcon,
        xIcon
      }
    }
  }
  </script>

  <style scoped lang="scss">
  @import '@/assets/styles/StrVariables.scss'
  @import '@/assets/styles/FeaturesTable.scss'
  </style>
