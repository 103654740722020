<template>
	<v-navigation-drawer>
		<v-list nav class="mt-2">
		<v-list-item id="nav-getstarted" :to="{ path: '/console/getstarted' }" prepend-icon="fas fa-clipboard-check" title="Get Started" value="getstarted"></v-list-item>
		<v-list-group id="nav-keys-vault" v-if="hasProcessingAccess || hasSdkAccess" value="Key Vault">
			<template v-slot:activator="{ props }">
			<v-list-item v-bind="props" prepend-icon="fas fa-key" title="Key Vault"></v-list-item>
			</template>
			<v-list-item v-if="hasProcessingAccess" id="nav-apikeys" :to="{ path: '/console/vault/api-keys' }" title="API Keys"></v-list-item>
			<v-list-item v-if="hasSdkAccess" id="nav-sdkkeys" :to="{ path: '/console/vault/sdk-keys' }" title="SDK Keys"></v-list-item>
		</v-list-group>
		<v-list-group value="downloads">
			<template v-slot:activator="{ props }">
			<v-list-item id="nav-downloads" v-bind="props" prepend-icon="fas fa-download" title="Downloads"></v-list-item>
			</template>
			<v-list-item id="nav-sdk-ios" :to="{ path: '/console/downloads/ios-sdk' }" title="Structure SDK for iOS" value="iossdk"></v-list-item>
			<v-list-item id="nav-sdk-xp" :to="{ path: '/console/downloads/xp-sdk' }" title="Structure SDK (Cross Platform)" value="xpsdk"></v-list-item>
			<v-list-item v-if="hasSpecialFileAccess" id="nav-special-files" :to="{ path: '/console/downloads/special-files' }" title="Special Files" value="special-files"></v-list-item>
		</v-list-group>
		<!-- <v-list-item id="nav-usage" :to="{ path: '/console/usage' }" prepend-icon="fas fa-chart-line" title="Usage" value="usage"></v-list-item> -->
		<v-list-group value="support">
			<template v-slot:activator="{ props }">
			<v-list-item v-bind="props" prepend-icon="fas fa-headset" title="Get Support" value="support"></v-list-item>
			</template>
			<v-list-item :href="'https://developer.structure.io/docs/tutorials/tutorials'" target="_blank" title="Tutorials" value="tutorials"></v-list-item>
			<v-list-item :href="'https://support.structure.io/'" target="_blank" title="Support Articles" value="articles"></v-list-item>
			<v-list-item :href="'https://forums.structure.io'" target="_blank" title="Forums" value="forums"></v-list-item>
			<v-list-item :href="'https://structure.io/contact'" target="_blank" title="Contact Us" value="contact"></v-list-item>
		</v-list-group>
		</v-list>
	</v-navigation-drawer>
</template>

<script>
import { computed, ref } from 'vue';
import { useStore } from 'vuex';

export default {
  setup() {
    const store = useStore();
    const hasSpecialFileAccess = computed(() => store.state.hasSpecialFileAccess);
	const permissions = computed(() => store.state.authentication.permissions || []);
	const hasSdkAccess = ref(false);
	const hasProcessingAccess = ref(false);

	if (permissions.value.includes('sdk_access')) {
		hasSdkAccess.value = true;
	}

	if (permissions.value.includes('processing_access')) {
		hasProcessingAccess.value = true;
	}

    return {
		hasSpecialFileAccess,
		hasProcessingAccess,
		hasSdkAccess
    };
  }
};
</script>