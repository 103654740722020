<template>
    <section class="dark-text-hero">
        <h1>{{ title }}</h1>
        <p>{{ subtitle }}</p>
    </section>
    </template>

    <script>
      export default {
        name: 'DarkTextHero',
        props: {
          title: {
            type: String,
            required: true
          },
          subtitle: {
            type: String,
            required: true
          },
        },

      }
      </script>

    <style scoped lang="scss">
    @import '@/assets/styles/StrVariables.scss'
    @import '@/assets/styles/DarkTextHero.scss'
    </style>