<template>
    <section class="home-section home-hero">
    <div class="home-hero-callout">
        <div class="home-hero-callout-new">
            New
        </div>
        <h2>Structure SDK 3.0</h2>
        <p>Advanced features like Scan Quality Indicator and Structure Cloud integration. Available now.</p>
        <div class="home-hero-callout-cta">
            <button @click="handleLogin" class="str-btn-primary">Sign up</button>
            <!-- <RouterLink to="/products/structure-sdk"><button class="str-btn-secondary fancy">Learn more</button></RouterLink> -->
        </div>
    </div>
    </section>

    <section class="home-section home-section-1">
    <div class="home-sec1-header">
        <h1>The world's most advanced spatial computing platform.</h1>
        <p>Structure SDK, the world’s most powerful mobile 3D scanning platform, now supports XRPro's Structure Sensor, Apple TrueDepth, and the LiDAR sensor in Apple's recent iPad Pro models. Build apps leveraging the power of spatial computing for millions of mobile devices.</p>
        <button @click="handleLogin">Log In or Register</button>
    </div>

    <div class="home-twofer-container">
        <div class="home-twofer-text">
            <h2>Acessible to all.</h2>
            <p>Never developed with a 3D sensor before? Structure SDK (iOS) includes high-level APIs that get you started quickly. Need 3D scanning and texturing? Volumetric reconstruction? Our APIs have you covered. Prefer to develop at a low level? You'll have direct access to the same raw data and low-level controls on Structure Sensor that we do.</p>
            <!-- <RouterLink to="/products/structure-sdk">Discover Structure SDK <img :src="rightArrow()" alt="right arrow icon"></RouterLink> -->
        </div>
        <div class="home-twofer-image">
            <img
                :src="homeImage('scanning.jpg')"
                alt="Structure Sensor scanning person" />
        </div>
    </div>

    <div class="home-twofer-container">
        <div class="home-twofer-image">
            <img
                :src="homeImage('structure-lite-iphone.png')"
                alt="Structure Lite scan on iPhone"
            />

        </div>
        <div class="home-twofer-text">
            <h2>Scanning with no additional equipment necessary.</h2>
            <p>With the optional Structure Lite package, enable your applications to be able to use Apple's in-device LiDAR and TrueDepth sensors. Expand your user base with the hardware they already have in their pocket.</p>
        </div>
    </div>

    <div class="home-twofer-container">
        <div class="home-twofer-text">
            <h2>Implement next-gen processing tools.</h2>
            <p>Structure Cloud integrates directly with Structure SDK to bring your real-time foot measurements and navicular drop differentials. Stay ahead of the competition with the next generation of analysis technologies.</p>
            <!-- <RouterLink to="/products/structure-cloud">Discover Structure Cloud<img :src="rightArrow()" alt="right arrow icon"></RouterLink> -->
        </div>
        <div class="home-twofer-image">
            <img
                :src="homeImage('cloud.jpg')"
                alt="computing cloud image"
            />
        </div>
    </div>
</section>

<FeaturesTable
    :title="'Product Features Comparison'"
    :subtitle="'Not sure where to start? Check out this handy chart.'"
/>

<DemoSection />

</template>

<script>
import FeaturesTable from "@/components/FeaturesTable.vue";
import DemoSection from "@/components/DemoSection.vue";
import { useAuth0 } from "@auth0/auth0-vue";

export default {
    components: {
        FeaturesTable,
        DemoSection
    },
  setup() {
    const { loginWithRedirect } = useAuth0();

    const homeImage = (image) => require(`@/assets/images/stock/${image}`);
    const rightArrow = () => require('@/assets/images/icons/arrow-right.svg');

    const handleLogin = () => {
      loginWithRedirect({
        appState: {
          target: "/console/getstarted",
        },
      });
    };

    return {
      handleLogin,
      homeImage,
      rightArrow
    };
  },
};
</script>

<style scoped lang="scss">
    @import '@/assets/styles/HomeView.scss'
    @import '@/assets/styles/StrButtons.scss'
</style>