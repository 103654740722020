<template>
	<div class="table-container" aria-describedby="keys-table">
		<v-table>
			<thead>
				<tr>
					<template v-for='(value, key) in apiResponseObject[0]' :key='key'>
						<th v-if="key !== 'id' && apiResponseObject && apiResponseObject.length > 0">
							{{ getColumnHeader(key) }}
							<span v-if="shouldShowSortButton(key)" class="sort-button" @click="toggleSort(key)">
								<v-icon :icon="getSortIcon(key)"></v-icon>
							</span>
						</th>
					</template>
					<th></th>
				</tr>
			</thead>
			<tbody>
				<tr v-for="(item, index) in apiResponseObject" :key="index">
					<template  v-for="(value, key) in item" :key="key">
						<td class="key-item" v-if="key !== 'id'">
						<template v-if="key !== 'id'">
							<span v-if="key=== 'value'" class="show-link" @click='showSecretString(item.value)'>
							•••• <v-icon :icon="'fas fa-eye'" title="click to show"></v-icon>
							</span>
							<span v-else :style="key === 'bundle_id' ? 'font-family: monospace;' : ''">
								{{ getFormattedValue(key, value) }}
							</span>
						</template>
						</td>
					</template>

					<td>
						<span class='delete-button' @click='confirmDelete(item.id)'>
							<v-icon :icon="'fas fa-xmark'"></v-icon>
						</span>
					</td>
				</tr>
			</tbody>
		</v-table>
	</div>
</template>

  <script>
  import { computed } from 'vue';
  import { useStore } from 'vuex';


  export default {
    name: 'KeysTable',
    setup() {
		const store = useStore();

		const apiResponseObject = computed(() => store.state.apiResponseObject || []);

        //function that shows the secret string value
		const showSecretString = (itemData) => {
			store.commit('SET_SECRET_VALUE', itemData);
			store.commit('SET_SHOW_SECRET_MODAL', true);
        }

		const getColumnHeader = (key) => {
			const columnHeaders = {
				created_on: 'Created On',
				last_used: 'Last Used',
				bundle_id: 'Bundle ID',
			};
			return columnHeaders[key] || key;
		};

		const formattedDateTime = (dateTimeString) => {
			if (!dateTimeString) {
				return '-';
			}
			const options = {
				year: 'numeric',
				month: 'numeric',
				day: 'numeric',
				// hour: 'numeric',
				// minute: 'numeric',
				// hour12: true,
			};
			const date = new Date(dateTimeString);
			return date.toLocaleString('en-US', options);
		};

		const getFormattedValue = (key, value) => {
			if (key === 'created_on' || key === 'last_used') {
				return formattedDateTime(value);
			}
			return value;
		};

		const confirmDelete = (itemData) => {
            store.commit('SET_DELETE_ITEM_DATA', itemData);
            store.commit('SET_SHOW_DELETE_CONFIRMATION_MODAL', true);
        }


		const shouldShowSortButton = (key) => {
            return ['created_on', 'last_used', 'name', 'bundle_id'].includes(key);
        };

		const toggleSort = (key) => {
			store.commit('TOGGLE_SORT', key);
		};

		const getSortIcon = (key) => {
			let sortDirection = store.state.sortDirection === 'asc' ? 'fas fa-sort-up' : 'fas fa-sort-down';
			return store.state.currentSortColumn === key ? sortDirection : 'fas fa-sort';
		};


      return {
		apiResponseObject,
		showSecretString,
		getColumnHeader,
		getFormattedValue,
		confirmDelete,
		shouldShowSortButton,
		toggleSort,
		getSortIcon,
      };
    },
  };
  </script>

<!-- <style scoped lang="scss">
    @import '@/assets/styles/KeysTable.scss'
</style> -->