import { watch, nextTick } from 'vue';

import axios from 'axios'
import { createRouter, createWebHistory } from 'vue-router';
import { authGuard, useAuth0 } from "@auth0/auth0-vue";
import { jwtDecode } from "jwt-decode";
import store from '@/store';

import DashboardContainer from '@/components/DashboardContainer.vue';
import GetStarted from '@/views/GetStarted.vue';
import KeysVault from '@/views/KeysVault.vue';
import AccountView from '@/views/AccountView.vue';
import Auth0Callback from '@/components/Auth0Callback.vue';
import EulaPage from '@/components/EulaPage.vue';
import SDKDownload from '@/views/SDKDownload.vue';
//import UsageView from '@/views/UsageView.vue';
import TermsView from '@/views/TermsView.vue';
import ErrorView from '@/views/ErrorView.vue';
import HomeView from '@/views/HomeView.vue';
//import ProductSDK from '@/views/ProductSDK.vue';
//import ProductCloud from '@/views/ProductCloud.vue';
import DemoView from '@/views/DemoView.vue';

const routes = [
    {
        path: '',
        name: 'HomeView',
        component: HomeView
    },
    // {
    //     path: '/products/structure-sdk',
    //     name: 'ProductSDK',
    //     component: ProductSDK
    // },
    // {
    //     path: '/products/structure-cloud',
    //     name: 'ProductCloud',
    //     component: ProductCloud
    // },
    {
        path: '/products/call',
        name: 'DemoView',
        component: DemoView
    },
    {
        path: '/auth0callback',
        name: 'auth0callback',
        component: Auth0Callback,
    },
    {
        name: 'Dashboard',
        path: '/console',
        component: DashboardContainer,
        beforeEnter: authGuard,
        meta: { requiresAuth: true },
        children: [
          {
            name: 'GetStarted',
            path: '/console/getstarted',
            components: {
              default: GetStarted,
            },
          },
            {
                name: 'KeysVault',
                path: '/console/vault/:activeView',
                components: {
                    default: KeysVault,
                },
            },
            {
              name: 'AccountView',
              path: '/console/account',
              components: {
                default: AccountView,
              },
            },
            {
              name: 'SDKDownload',
              path: '/console/downloads/:package',
              components: {
                default: SDKDownload,
              },
            },
            // {
            //   name: 'Billing',
            //   path: '/console/billing',
            //   components: {
            //     default: SDKDownload,
            //   },
            // },
            // {
            //   name: 'Usage',
            //   path: '/console/usage',
            //   components: {
            //     default: UsageView,
            //   },
            // },
            {
              name: 'TermsView',
              path: '/terms',
              components: {
                default: TermsView,
              },
            },
            {
              name: 'ErrorView',
              path: '/error',
              components: {
                default: ErrorView,
              },
            },
        ],
    },
    {
        name: 'Eula',
        path: '/eula',
        component: EulaPage
    }
];

const router = createRouter({
  history: createWebHistory('/'),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 }
  },
});

const checkAndCreateUser = async (user, getAccessTokenSilently) => {
  const token = await getAccessTokenSilently();
  const headers = { Authorization: `Bearer ${token}` };
  try {
      const piccolo_user = await axios.get(`${process.env.VUE_APP_CBE_BASE_URL}/piccolo/users/${user.sub}`, { headers });
      return piccolo_user.data;
  } catch (error) {
      if (error.response && error.response.status === 404) {
          const userData = {
              auth0_id: user.sub,
              email: user.email,
              organization: '',
              is_active: true,
              terms_version: Number(process.env.VUE_APP_TERMS_VERSION),
          };
          const response = await axios.post(`${process.env.VUE_APP_CBE_BASE_URL}/piccolo/users`, userData, { headers });
          return response.data;
      } else {
          console.error('Error checking user existence:', error);
          throw error;
      }
  }
};

const fetchUserData = async (user, getAccessTokenSilently) => {
  const userData = await checkAndCreateUser(user, getAccessTokenSilently);
  const token = await getAccessTokenSilently();
  const decoded = jwtDecode(token);
  const permissions = decoded.permissions;
  const roles = user['https://structure.io/roles'];

  store.commit('SET_AUTHENTICATION', {
      userIsAuthorized: true,
      roles: roles,
      permissions: permissions,
      termsVersion: userData.terms_version
  });

  const specialFiles = ['3dfacescan', '3dfootscan', '3dchonkscan', 'structurelite'];
  if (specialFiles.some(file => permissions.includes(file))){
      store.commit('SET_HAS_SPECIAL_FILE_ACCESS', true);
  }

  return userData;
};

router.beforeEach(async (to, from, next) => {
const { user, isLoading, isAuthenticated, getAccessTokenSilently } = useAuth0();

// Public routes that don't require auth check
const publicRoutes = ['/', '/products/structure-sdk', '/products/structure-cloud', '/products/call', '/login', '/eula', '/auth0callback', '/error', '/terms'];
if (publicRoutes.includes(to.path)) {
  next();
  return;
}

// Wait for Auth0 to finish loading
const waitForAuth0 = () => {
  return new Promise((resolve) => {
    if (!isLoading.value) {
      resolve();
      return;
    }

    const unwatch = watch(
      () => isLoading.value,
      async (newLoading) => {
        if (!newLoading) {
          unwatch();
          await nextTick();
          resolve();
        }
      }
    );
  });
};

await waitForAuth0();

// Check authentication
if (!isAuthenticated.value) {
  next('');
  return;
}

// User is authenticated, now check and create user if necessary, and fetch data
if (!user.value || !user.value.sub) {
  next('/error');
  return;
}

try {
  store.commit('SET_SHOW_LOADING_MODAL', true);
  const userData = await fetchUserData(user.value, getAccessTokenSilently);

  const requiredTermsVersion = Number(process.env.VUE_APP_TERMS_VERSION);

  if (userData.terms_version !== null && userData.terms_version >= requiredTermsVersion) {
    // User has accepted the required terms version
    if (to.matched.some(record => record.meta.requiresAuth)) {
      // Route requires auth, proceed
      next();
    } else {
      // Route doesn't require auth, redirect to dashboard or desired default route
      next('/');
    }
  } else {
    // User hasn't accepted the latest terms
    next('/terms');
  }
} catch (error) {
  console.error('Error checking user or terms:', error);
  store.commit('SET_SHOW_ERROR_MODAL', true);
  store.commit('SET_ERROR_MESSAGE', 'Error fetching user data');
  next('/error');
} finally {
  store.commit('SET_SHOW_LOADING_MODAL', false);
}
});

export { router, routes };