<template>
    <v-card class="pa-10 rounded-large">
        <h1>SDK Developer License Agreement</h1>
        <TermsText />
        <v-btn class="float-right" color="primary" @click="acceptTerms">Accept</v-btn>
    </v-card>
</template>

<script>
  import { computed, onMounted, ref } from 'vue';
  import { useStore } from 'vuex';
  import axios from 'axios';
  import { useRouter } from 'vue-router';
  import { useAuth0 } from '@auth0/auth0-vue';

  import TermsText from '../components/TermsText.vue';

  export default {
    name: 'TermsView',
    components: {
      TermsText,
    },
    setup() {
        const store = useStore();
        const { user, getAccessTokenSilently } = useAuth0();
        const router = useRouter();

        const previousRoute = computed(() => store.state.previousRoute);

        const token = ref('');

        const getAccessToken = async () => {
            token.value = await getAccessTokenSilently();
            return { headers: { Authorization: `Bearer ${token.value}` } };
        };

        const checkTermsVersion = async () => {
            const authHeaders = await getAccessToken();
            const piccolo_user = await axios.get(`${process.env.VUE_APP_CBE_BASE_URL}/piccolo/users/${user.value.sub}`, authHeaders);
            const acceptedTermsVersion = piccolo_user.data.terms_version;


            if (acceptedTermsVersion == Number(process.env.VUE_APP_TERMS_VERSION)) {
                if(previousRoute.value != null){
                    router.replace(previousRoute.value);
                }
            }
        }

        const acceptTerms = async () => {
            try {
                const authHeaders = await getAccessToken();
                await axios.patch(`${process.env.VUE_APP_CBE_BASE_URL}/piccolo/users/${user.value.sub}`, { terms_version: Number(process.env.VUE_APP_TERMS_VERSION) }, authHeaders);
                store.commit('SET_AUTHENTICATION', {termsVersion: Number(process.env.VUE_APP_TERMS_VERSION) });
                router.push('/console/getstarted');
            }
            catch (error) {
                console.error('Error updating user data:', error);
            }
        };

        onMounted(async () => {
            checkTermsVersion();
        });

        return {
            acceptTerms
        }
    },
  };
  </script>

<style scoped lang="scss">
    @import '@/assets/styles/EulaPage.scss'
</style>