<template>
    <DarkTextHeroShort :title="'End User License Agreement'" />
    <EulaText />
</template>

  <script>
  import DarkTextHeroShort from './DarkTextHeroShort.vue';
  import EulaText from './EulaText.vue';
  import { useRouter } from 'vue-router';

  export default {
    name: 'EulaPage',
    components: {
      EulaText,
      DarkTextHeroShort
    },
    setup() {
      const router = useRouter();

      const goBack = () => {
        router.back();
      };

      return {
        goBack,
        router,
      };
    },
  };
  </script>

<style scoped lang="scss">
    @import '@/assets/styles/EulaPage.scss'
</style>