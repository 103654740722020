<template>

    <section>
        <aside>
            <p>Last Updated: 2025-01-15</p>
        </aside>
        <p>
            Please read the following agreement (the “License”) carefully as it contains the legal terms and conditions that you must agree to when you license an SDK from Structure. As used in this License, “we” or “Structure” means XRPro, LLC and its subsidiaries and affiliates, and “you” and “Licensee” mean the individual or entity using a Structure SDK and subsidiaries or affiliates of that individual or entity.
        </p>
        <p>
            Any user who wants to license a Structure SDK must accept this License without change. By clicking the “I Agree” button on this page or by using the SDK you are agreeing to be bound by this Agreement on behalf of yourself or your employer or other entity, as applicable. You represent and warrant that you have full legal authority to bind your employer or such entity to this Agreement. If you do not have the requisite authority, you may not accept the Agreement or use the SDK.
        </p>
    </section>
    <section>
        <header>
            <h3>1. Definitions</h3>
        </header>
           <p>
            <ul>
                <li>“Application” means any application created by you using a Structure SDK.</li>
                <li>“Approval Process” means the process by which Licensee submits Applications to Structure for review and approval prior to release.</li>
                <li>“Commercial Use” means any use of the SDK in connection with an Application or other product or service from which you receive, or intend to receive, direct or indirect revenue, compensation, or other financial consideration (including, but not limited to, sales, subscription fees, in-app purchases, revenue-sharing, or licensing fees).</li>
                <li>“Non-Commercial Application” means an Application for which you do not receive any direct or indirect compensation or consideration from licensees, sub-licensees, or other parties.</li>
                <li>“End-User License” means the perpetual license to end-users of an Application further described in the section entitled “License” below.</li>
                <li>“Structure Hardware” means any hardware product manufactured by, on behalf of, or sold by, Structure, including, but not limited to, Structure Sensor, Structure Sensor Pro, Structure Core, and physical accessories for those products.</li>
                <li>“Structure SDK” means the three-dimensional vision libraries and application shell code provided by Structure under the names “Structure SDK” and “Structure Core SDK.”</li>
                <li>“Structure Products” means any product or service provided by Structure, including but not limited to Structure Hardware and Structure SDKs.</li>
                <li>“Structure Websites” means any website owned or operated by or on behalf of Structure including, without limitation, structure.io.</li>
                <li>“Publicity Materials” means any media provided by Structure to Licensee for the purpose of publicizing integration with a Structure SDK.</li>
                <li>“Services” means any hosted applications, data storage, image or data processing services, or other services provided by Structure including, but not limited to, online processing services, development assistance, and server-based processing.</li>
                <li>“Structure Sensor” means any hardware sensing products manufactured by or on behalf of, or sold by, Structure, including, but not limited to, the original Structure Sensor (ST01), Structure Sensor (Mark II) (ST02A), Structure Sensor (Mark II) Pro (ST02B), Structure Core (ST02, ST02-C, ST02D, ST02D-C), and Structure Sensor 3 (ST03).</li>
                <li>“Third-party sensor” means hardware sensing products not manufactured by or on behalf of, or sold by, Structure, including Apple LiDAR and Apple TrueDepth cameras.</li>
                <li>“Third-party SDK” means three-dimensional vision libraries, three-dimensional scanning libraries, three-dimensional reconstruction or SLAM (simultaneous localization and mapping) libraries and application shell code not provided, developed, distributed by or on behalf of, or sold by Structure.</li>
            </ul>
        </p>
    </section>
    <section>
        <header>
            <h3>2. License</h3>
        </header>
        <p>
            Subject to the terms of this License, Structure grants you a non-exclusive, worldwide license during the term of this Agreement to use, copy, encode, store, archive, distribute, transmit, modify, translate, publicly display, and publicly perform the Publicity Materials and Applications developed by you with the licensed Structure SDK, and any updates, improvements, or modifications thereto, for (a) Applications that rely on a Structure Sensor(s) to function, or (b) Non-Commercial Applications. All users of an Application who download the Application or any update thereto during the term of this Agreement shall have a perpetual, worldwide sublicense to the licensed SDK to the extent required to use such Application.    </p>
        <p>
            <strong>Note:</strong> Passing any App Review Process for public release does not automatically grant you a Commercial License if your Application is monetized or otherwise constitutes Commercial Use.
        </p>
    </section>
    <section>
        <header>
            <h3>3. Restrictions</h3>
        </header>
        <p>
            You agree that the licensed SDK contains valuable confidential information and you agree that you will not copy, modify, reverse engineer, decompile, disassemble, adapt, or create derivative works (other than Applications) of, the SDK or its contents. You will not distribute the SDK to any third party, or directly or indirectly (or allow any third party) to: (i) use the SDK to create (or facilitate the creation of) any product or service other than the Application; (ii) rent or lease the SDK; (iii) combine the SDK with other software such that you restrict or charge for access to the SDK.
        </p>
        <p>
            Commercial use of the SDK (including, but not limited to, any Commercial Use as defined in Section 1.3) requires a separate written commercial license (“Commercial License”) between you and Structure. You must promptly contact Structure at support+sdk@structure.io (or such other address as Structure may designate) before you engage in Commercial Use of the SDK or distribute an Application that is intended to generate revenue or other financial benefit. The specific terms and fees for the Commercial License shall be determined on a case-by-case basis.
        </p>
        <p>
            Third-party sensors: If you plan to develop an Application that uses third-party sensors for any Commercial Use, you must also contact Structure to discuss any technical or licensing nuances as part of the same Commercial License process.
        </p>
        <p>
            You will (i) use the licensed SDK only for the purposes of creating the Application, (ii) keep any unreleased updates to the licensed SDK confidential, (iii) integrate the latest released version of the licensed SDK into the Application upon any update on the App Store, and (iv) follow all guidelines and steps in the Approval Process, including staying in compliance with the most recent Approval Process at the time of your Application submission (including submission of updates) to the App Store.
        </p>
        <p>
            You are not allowed, unless authorized in writing by Structure, to use third-party SDKs that perform scanning, 3D reconstruction, or SLAM (simultaneous localization and mapping).
        </p>
        <p>
            Any failure to obtain a Commercial License when required under this Agreement will constitute a material breach. In such event, Structure will provide written notice specifying the nature of the breach, and you shall have fifteen (15) days from receipt of such notice to either (a) cure the breach by obtaining the requisite Commercial License and paying any applicable fees, or (b) cease all unauthorized commercial activity. If you fail to cure within this 15-day period, Structure may, in addition to any other remedies available at law or in equity, immediately terminate your license and require that you cease all use of the SDK and remove, disable, or discontinue the distribution of any Application that incorporates the SDK. This right to immediate termination for an uncured material breach is in addition to (and does not limit) the 30-day general termination right provided in Section 8.
        </p>
    </section>
    <section>
        <header>
            <h3>4. Consent to Use of Data</h3>
        </header>
        <p>
            Subject to Structure’s Privacy Policy, Licensee agrees that Structure may obtain, retain, and use asynchronously-logged debugging and status data from the Structure SDK. Licensee further agrees that if using third-party sensors, Applications may be blocked from using the Structure SDK if asynchronously-logged status data is not available at least once every three months.
        </p>
    </section>
    <section>
        <header>
            <h3>5. Disclaimers and Limitations</h3>
        </header>
        <p>
            THE STRUCTURE SDKS ARE PROVIDED “AS IS” WITHOUT WARRANTY OF ANY KIND, EITHER EXPRESSED OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT.
        </p>
        <p>
            EXCEPT FOR THE INDEMNIFICATION OBLIGATIONS DESCRIBED BELOW, (1) UNDER NO CIRCUMSTANCES SHALL EITHER PARTY BE LIABLE FOR ANY INDIRECT, SPECIAL, INCIDENTAL, OR CONSEQUENTIAL DAMAGES THAT MAY ARISE FROM THIS AGREEMENT, SUCH AS, BUT NOT LIMITED TO, LOSS OF REVENUE OR ANTICIPATED PROFITS OR LOST BUSINESS, AND (2) IN NO EVENT SHALL EITHER PARTY’S TOTAL LIABILITY TO THE OTHER PARTY FOR ANY CLAIM HEREUNDER EXCEED $1,000.
        </p>
    </section>
    <section>
        <header>
            <h3>6. Indemnification</h3>
        </header>
        <p>
            Licensee shall, at its own expense, indemnify, defend and hold harmless Structure and any of its officers, directors, employees, representatives, licensees and agents from and against and in respect of any and all claims, liabilities, allegations, suits, actions, investigations, judgments, deficiencies, settlements, inquiries, demands or other proceedings of whatever nature or kind, whether formal or informal, brought against Structure or any or its officers, directors, employees, representatives, licensees or agents, as well as from and against and in respect of any and all damages, liabilities, losses, costs, charges, fees and expenses, including without limitation reasonable legal fees and expenses, as and when incurred, relating to, based upon, incident to, arising from, or in connection with any claim or allegation that the Application infringes in any manner any intellectual property right or any other right of any third party, violates any law or regulation, or violates any rights of any person or entity, including without limitation rights of publicity, privacy or personality, or has otherwise resulted in any consumer fraud, product liability, tort, deceptive trade practice, breach of contract, injury, damage or harm of any kind to any third party. Licensee agrees that it may not, without Structure’s prior written consent, enter into any settlement or compromise of any claim that results in any admission of liability or wrongdoing on the part of Structure.
        </p>
    </section>
    <section>
        <header>
            <h3>7. Miscellaneous</h3>
        </header>
        <p>
            All notices, requests and other communications called for by this License shall be deemed to have been given immediately if (a) publicly published and displayed by Structure on a Structure Website, (b) sent by Structure to you via electronic mail at the email address you used when you first accepted this License, or (c) sent by you via electronic mail to Structure at support+sdk@structure.io.
        </p>
        <p>
            No waiver by either party of any breach or default hereunder shall be deemed to be a waiver of any preceding or subsequent breach or default. This Agreement will bind and inure to the benefit of each party’s permitted successors and assigns. If any provision or part of a provision in this Agreement is held to be illegal, invalid, or unenforceable by a court or other decision-making authority of competent jurisdiction, then the remainder of the provision will be enforced so as to effect the intention of the parties, and the validity and enforceability of all other provisions in this Agreement will not be affected or impaired.
        </p>
        <p>
            Neither party may assign this License, in whole or in part, without the other party’s written consent; provided, however, that either party may assign this Agreement without consent in connection with any merger, consolidation, any sale of all or substantially all of its assets or any other transaction in which more than 50% of its voting securities are transferred, or in Structure’s case any sale of all or substantially all of the assets of any of its business units or of Structure. Any attempt to assign or transfer this Agreement other than in accordance with this provision will be null and void.
        </p>
        <p>
            This Agreement shall be governed by and construed using Michigan law. The parties consent to venue and the exclusive jurisdiction of the state and federal courts of Michigan.
        </p>
    </section>
    <section>
        <header>
            <h3>8. Effectiveness, Amendment and Termination</h3>
        </header>
        <p>
            This License will become effective upon your acceptance of its terms and will continue in effect until terminated by either you or Structure. This Agreement may be updated by Structure at any time and shall become binding upon Licensee immediately upon its public display on a Structure Website. Upon such update, Licensee may be required to review and agree to the updated agreement in order for the License to remain in effect. Either party may terminate this License for any reason with thirty (30) days’ prior notice. The right to terminate for a material breach under Section 3 (Breach of Commercial Use Requirement) remains unaffected by this general right to terminate. The End-User License, Restrictions (including the commercial use requirements), and Disclaimers and Limitations of this License will survive the termination of this Agreement for any reason.
        </p>
    </section>
    </template>

    <script>
    export default {
        name: 'TermsText',
        setup() {
        },
    };
    </script>
