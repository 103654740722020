<template>
  <header class="str-base-header" id="str-base-header">
    <nav>
      <div class="str-header-container">
        <div class="str-header-logo">
          <a href="/"><img id="str-logo" src="@/assets/logo.svg" /></a>
        </div>
        <div class="str-nav-links">
          <div class="header-links-left">
            <ul>
              <li class="str-dropdown">
                <a href="#" class="str-dropdown-toggle" id="str-nav-about">Products</a>
                <ul class="str-dropdown-menu">
                  <!-- <li><RouterLink to="/products/structure-sdk">Structure SDK</RouterLink></li>
                  <li><RouterLink to="/products/structure-cloud">Structure Cloud</RouterLink></li> -->
                  <li><RouterLink to="/products/call">Schedule a Call</RouterLink></li>
                </ul>
              </li>
              <li class="str-dropdown">
                <a href="#" class="str-dropdown-toggle" id="str-nav-products">Resources</a>
                <ul class="str-dropdown-menu">
                  <li><a href="https://developer.structure.io/docs/documentation/structure/" target="_blank" @click="handleDocumentationClick" id="documentation-link">Documentation</a></li>
                  <li><a href="https://developer.structure.io/docs/tutorials/tutorials/">Tutorials</a></li>
                  <li><RouterLink to="/eula">EULA</RouterLink></li>
                </ul>
              </li>
              <li class="str-dropdown">
                <a href="#" class="str-dropdown-toggle" id="str-nav-support">Support</a>
                <ul class="str-dropdown-menu">
                  <li><a href="https://support.structure.io">Help Center</a></li>
                  <li><a href="https://structure.io/contact">Contact Us</a></li>
                  <li><a href="https://support.structure.io/article/417-structure-sdk-ios-release-notes">Release Notes</a></li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
        <div class="str-nav-slant"></div>
        <div class="header-links-dev">
          <button v-if="!isAuthenticated" @click="handleLogin">Log In</button>
          <button v-else-if="isAuthenticated && isOnPublicRoute" @click="router.push('/console/getstarted')">Back to Console</button>
          <button v-else-if="isAuthenticated && !isOnPublicRoute" @click="handleLogout">Log Out</button>
          <template v-if="isMobile">
      <img
        id="header-mobile-menu-btn"
        :src="mobileIcon('menu')"
        alt="menu icon"
        v-if="!mobileMenuOpen"
        @click="openMenu"
      />
      <img
        id="header-mobile-menu-close"
        :src="mobileIcon('close')"
        alt="close icon"
        v-if="mobileMenuOpen"
        @click="closeMenu"
      />
    </template>

        </div>
        <div id="header-mobile-menu" :class="mobileMenuClass">
          <ul>
            <li><img id="str-logo" src="https://store-4fwfwlzl1t.mybigcommerce.com/content/images/logos/StructureLogo-cyn-wht.svg" /></li>
            <li v-for="(item, index) in mobileMenuItems" :key="index" class="mobile-accordion">
              <div class="mobile-accordion-header" @click="toggleAccordion(item)">
                {{ item.title }}
                <img
                  class="footer-btn footer-btn-open"
                  :src="mobileIcon('plus')"
                  :class="{ active: item.open }"
                  alt="open icon"
                />
              </div>
              <ul v-show="item.open" class="mobile-accordion-content">
                <li v-for="(link, idx) in item.links" :key="idx"><a :href="link.href">{{ link.text }}</a></li>
              </ul>
            </li>

            <li><button v-if="!isAuthenticated" @click="handleLogin">Log In</button>
          <button v-else-if="isAuthenticated && isOnPublicRoute" @click="router.push('/console/getstarted')">Back to Console</button>
          <button v-else-if="isAuthenticated && !isOnPublicRoute" @click="handleLogout">Log Out</button></li>
          </ul>
        </div>
      </div>
    </nav>
  </header>
</template>

<script>
import { useAuth0 } from "@auth0/auth0-vue";
import { useRouter, useRoute } from "vue-router";
import axios from 'axios';
import { onMounted, onUnmounted, ref, computed } from "vue";

import { publicPages } from '@/config/routesConfig';


export default {
  setup() {
    const { isAuthenticated, loginWithRedirect, getAccessTokenSilently, logout, user } = useAuth0();
    const router = useRouter();
    const route = useRoute();

    const token = ref('');

    const getAccessToken = async () => {
        token.value = await getAccessTokenSilently();
        return { headers: { Authorization: `Bearer ${token.value}` } };
    };

    const mobileMenuOpen = ref(false);
    const mobileMenuClass = ref('header-mobile-menu-closed');
    const mobileMenuItems = ref([
      { title: 'About', open: false, links: [{ text: 'Careers', href: '/careers' }, { text: 'Meet the team', href: '/team' }, { text: 'Medical journals', href: '/medical-journals' }] },
      { title: 'Products', open: false, links: [{ text: 'Structure Sensor 3', href: '/structure-sensor-3' }, { text: 'Structure SDK', href: '/structure-sdk' }, { text: 'Structure Lite', href: '/structure-lite' }, { text: 'Structure Capture', href: '/structure-capture' }, { text: 'Accessories', href: '/shop-all/accessories' }] },
      { title: 'Support', open: false, links: [{ text: 'FAQs', href: '/faqs' }, { text: 'Get Started', href: '/getstarted' }, { text: 'Help Center', href: '/help' }, { text: 'Contact Us', href: '/contact' }] },
    ]);

    const handleLogin = () => {
      loginWithRedirect({
        appState: { target: "/console/getstarted" },
      });
    };

    const handleLogout = () => {
      logout({
        logoutParams: {
          returnTo: process.env.VUE_APP_BASE_URL + '',
        },
      });
    };

    const handleDocumentationClick = async () => {

      if (isAuthenticated) {
        try {
          const authHeaders = await getAccessToken();
          const auth0Id = user.value.sub;
          console.log(process.env.VUE_APP_CBE_BASE_URL);
          await axios.patch(`${process.env.VUE_APP_CBE_BASE_URL}/piccolo/users/onboardings/${auth0Id}`, { has_read_documentation: true }, authHeaders);
        } catch (error) {
          console.error('Error updating onboarding data:', error);
        }
      }
    };

    const openMenu = () => {
      mobileMenuClass.value = 'header-mobile-menu-open';
      mobileMenuOpen.value = true;
    };

    const closeMenu = () => {
      mobileMenuClass.value = 'header-mobile-menu-closed';
      mobileMenuOpen.value = false;
    };

    const isMobile = ref(window.innerWidth <= 912);

    const updateIsMobile = () => {
      isMobile.value = window.innerWidth <= 912;
    };


    const handleResize = () => {
      if (isMobile.value) {
        closeMenu();
      }
    };

    const toggleAccordion = (item) => {
      const index = mobileMenuItems.value.indexOf(item);
      if (index !== -1) {
        mobileMenuItems.value[index] = { ...item, open: !item.open }; // Reassign the object to ensure reactivity
      }
    };

    const isOnPublicRoute = computed(() => publicPages.includes(route.path));


    const mobileIcon = (icon) => require(`@/assets/images/icons/${icon}.svg`);

    onMounted(() => {
      updateIsMobile();
      window.addEventListener('resize', updateIsMobile);
      window.addEventListener("resize", handleResize);
    });

    onUnmounted(() => {
      window.removeEventListener('resize', updateIsMobile);
      window.removeEventListener("resize", handleResize);
    });

    return {
      isAuthenticated,
      handleLogin,
      handleLogout,
      handleDocumentationClick,
      isMobile,
      openMenu,
      closeMenu,
      mobileIcon,
      mobileMenuClass,
      mobileMenuItems,
      mobileMenuOpen,
      toggleAccordion,
      isOnPublicRoute,
      router
    };
  },
};
</script>

<style scoped lang="scss">
    @import '@/assets/styles/DashboardTopNav.scss'
    @import '@/assets/styles/StrButtons.scss'
</style>