<template>
    <div class="error-modal modal" v-if="showModal">
        <div class="modal-content">
                <span class="close" id="close-button" @click="closeErrorModal">&times;</span>
                <h2>Oh no, something went wrong!</h2>
                <p>The Old Ones prophesy this might be the issue:</p>
                <p class="error-message"><em>{{ errorMessage }}</em></p>
                <p>Please try again. If the issue keeps happening, please contact <a href="mailto:support@structure.io">support@structure.io</a>.</p>
        </div>
    </div>
</template>

<script>
    import { computed } from 'vue';
    import { useStore } from 'vuex';
    export default {
        name: 'ErrorModal',
        props: {
            showModal: Boolean,
            onClose: Function,
        },
        setup(props) {
            const store = useStore();
            const errorMessage = computed (() => store.state.errorMessage);

            const closeErrorModal = () => {
                store.commit('SET_SHOW_ERROR_MODAL', false);
                store.commit('SET_ERROR_MESSAGE', null);
                props.onClose?.();
            };

            return {
                errorMessage,
                closeErrorModal,
            };
        }
    };
</script>

<style lang="scss" scoped>

	@import '@/assets/styles/CommonModalStyles.scss'

</style>
