<template>
    <div class="dashboard-container">
        <router-view />
    </div>
</template>

<script>

export default {

    setup() {
        return {
        };
    },
};
</script>

<style scoped lang="scss">
    @import '../assets/styles/DashboardContainer.scss'
</style>