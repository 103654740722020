import { createStore } from 'vuex';

export default createStore({
	state() {
		return {
		isAccessTokenSet: false,
		authentication: {
			userIsAuthorized: true,
			roles: [],
			permissions: [],
			termsVersion: []
		},
		hasSpecialFileAccess: false,
		previousRoute: null,
		dashboardActiveView: null,
		showSignOutModal: false,
		showDynamicModal: false,
		showErrorModal: false,
		showDeleteConfirmationModal: false,
		showLoadingModal: false,
		showKeyModal: false,
		showSecretModal: false,
		keyValue: null,
		secretValue: null,
		deleteItemData: null,
		apiResponseObject: {},
		fullResponseObject: {},
		sdkReleaseObject: {},
		errorMessage: null,
		currentPage: 1,
		totalRows: 0,
		totalPages: 1,
		currentSortColumn: null,
		sortDirection: 'asc',
		};
	},
	mutations: {
		SET_ACCESS_TOKEN(state, value) {
			state.authentication.accessToken = value;
		},
		SET_AUTHENTICATION(state, authData) {
			state.authentication = authData;
			state.isAccessTokenSet = true;
		},
		SET_HAS_SPECIAL_FILE_ACCESS(state, value) {
			state.hasSpecialFileAccess = value;
		},
		SET_PREVIOUS_ROUTE(state, route) {
			state.previousRoute = route;
		},
		SET_API_RESPONSE_OBJECT(state, object) {
			state.apiResponseObject = object;
		},
		SET_FULL_RESPONSE_OBJECT(state, object) {
			state.fullResponseObject = object;
		},
		SET_SHOW_DYNAMIC_MODAL(state, value){
			state.showDynamicModal = value;
		},
		SET_DELETE_ITEM_DATA(state, value){
			state.deleteItemData = value;
		},
		SET_SHOW_DELETE_CONFIRMATION_MODAL(state, value){
			state.showDeleteConfirmationModal = value;
		},
		SET_SHOW_ERROR_MODAL(state, value){
			state.showErrorModal = value;
		},
		SET_SHOW_SIGN_OUT_MODAL(state, value){
			state.showSignOutModal = value;
		},
		SET_SHOW_LOADING_MODAL(state, value){
			state.showLoadingModal = value;
		},
		SET_SHOW_KEY_MODAL(state, value){
			state.showKeyModal = value;
		},
		SET_SHOW_SECRET_MODAL(state, value){
			state.showSecretModal = value;
		},
		SET_KEY_VALUE(state, value){
			state.keyValue = value;
		},
		SET_SECRET_VALUE(state, value){
			state.secretValue = value;
		},
		SET_ERROR_MESSAGE(state, value){
			state.errorMessage = value;
		},
		SET_DASHBOARD_ACTIVE_VIEW(state, value){
			state.dashboardActiveView = value;
		},
		SET_CURRENT_PAGE(state, value){
			state.currentPage = value;
		},
		SET_TOTAL_PAGES(state, value){
			state.totalPages = value;
		},
		SET_TOTAL_ROWS(state, value){
			state.totalRows = value;
		},
		TOGGLE_SORT(state, column) {
			if (state.currentSortColumn === column) {
				// If clicking on the same column, toggle the sort direction
				state.sortDirection = state.sortDirection === 'asc' ? 'desc' : 'asc';
			} else {
				// If clicking on a different column, reset the sort direction
				state.currentSortColumn = column;
				state.sortDirection = 'asc';
			}

			// Perform the actual sorting based on the current state
			state.apiResponseObject.sort((a, b) => {
				const valueA = a[column];
				const valueB = b[column];

				if (valueA < valueB) {
					return state.sortDirection === 'asc' ? -1 : 1;
				}
				if (valueA > valueB) {
					return state.sortDirection === 'asc' ? 1 : -1;
				}
				return 0;
			});
		},
		SET_SDK_RELEASE_OBJECT(state, value) {
			state.sdkReleaseObject = value;
		}

	},
	actions: {
		showSignOutModal: (state) => {
			state.SET_SHOW_SIGN_OUT_MODAL(true);
		},
		closeSignOutModal: (state) => {
			state.SET_SHOW_SIGN_OUT_MODAL(false);
		},
	},
	getters: {
		isAccessTokenSet(state) {
		return state.isAccessTokenSet;
		},
	},
});